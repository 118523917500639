import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Label from "../../components/Label";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  _delete_page,
  _project_details_listing,
} from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import dummyImage from "../../images/website-placeholder.png";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { useSnackbar } from "notistack";

export default function PagesDetailList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true); // Loading state

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const pageDetailList = async () => {
    const project_list_response = await _project_details_listing(id);
    setData(project_list_response.project);
    setLoading(false);
    // console.log(project_list_response, "cbhdbcdbcdj");

    // console.log(data, "datattatat");
  };

  useEffect(() => {
    pageDetailList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    // console.log(selectedPage, "selected");
    if (selectedPage.page_slug === "index") {
      handleMenuClose();
      enqueueSnackbar("Cannot delete the index page", { variant: "error" });

      return;
    }

    const delete_resp = await _delete_page(selectedPage._id);
    // console.log("Delete page:", delete_resp);

    if (delete_resp.code == 200) {
      setOpenDelete(false);
      pageDetailList();
      enqueueSnackbar("Page Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };
  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  return (
    <>
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Name
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Image</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Slug
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Version Count
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Update At
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Meta Title
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Payment Id
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.pages &&
              data.pages.length > 0 ? (
                data.pages.map((dat, index) => (
                  <TableRow
                    key={dat.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{dat.page_name}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: 120,
                          height: 70,
                          border: "1px solid #ccc",
                          borderRadius: "0.4rem",
                        }}
                      >
                        <img
                          src={
                            dat.image ? s3baseUrl + "/" + dat.image : dummyImage
                          }
                          alt={dat.image ? "Page Image" : ""}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = dummyImage;
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "0.4rem",
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>/{dat.page_slug}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {dat.page_version_count}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {moment(dat.updatedAt).format("LLL")}
                    </TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      {dat.meta_title || "-"}
                    </TableCell>

                    <TableCell>
                      <Label
                        variant="ghost"
                        color={
                          (!data.Project.payment_page_id && "error") ||
                          "success"
                        }
                      >
                        {data.Project.payment_page_id === dat._id
                          ? "True"
                          : "False"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="page-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuOpen(e, dat)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="page-menu"
                        anchorEl={anchorEl}
                        open={Boolean(
                          anchorEl && selectedPage && selectedPage.id === dat.id
                        )}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ):(
                <TableRow>
                <TableCell colSpan={12} align="center">
                  No Pages Detail  Found
                </TableCell>
              </TableRow>
              )
              
              }
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
    </>
  );
}
