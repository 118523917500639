import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import CustomConfirmation from "src/components/CustomConfirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  _version_list,
  _delete_project_version,
} from "src/DAL/projects/projects";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import { _project_list, _delete_project } from "src/DAL/projects/projects";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import { clientBaseUri, s3baseUrl } from "src/config/config";
import CustomPopover from "src/components/CustomPopover";
import moment from "moment";
import { _get_customer_token_for_login } from "src/DAL/customer/customer";
import EditProject from "../Edit-Project/EditProject";
import debounce from "src/utils/debounce";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "#", label: "#", alignRight: false },
  { id: "project_name", label: "Name", alignRight: false },
  { id: "_id", label: "Project ID", alignRight: false },
  { id: "user_name", label: "Customer Name", alignRight: false },
  { id: "domain", label: "Custom Domain", alignRight: false },
  { id: "page_list", label: "Page Count", alignRight: false },
  { id: "preview_link", label: "Preview", alignRight: false },
  { id: "is_published", label: "Published", alignRight: false },
  { id: "ssl_status", label: "SSL", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated At", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.project_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.user_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.domain.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user._id.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState([]);
  const [checkVersionsById, setCheckVersionsById] = useState();
  const [versions, setVersions] = useState();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleOpenEdit = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseEdit = () => {
    setIsDrawerOpen(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    console.log("check this ", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = data.length === 0;

  const get_project_list = async () => {
    const postData = {
      search: filterName,
    };
    const project_list_resp = await _project_list(page, rowsPerPage, postData);
    if (project_list_resp.code == 200) {
      console.log(project_list_resp);
      const projects = project_list_resp.project.map((project, index) => {
        return {
          ...project,
          user_name: project.user?.first_name + " " + project.user.last_name,
          preview_link: `${s3baseUrl}/${project._id}/index.html`,
          count: index + 1 + rowsPerPage * page,
        };
      });
      setData([...projects]);
      setTotalPages(project_list_resp.count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(project_list_resp.message, { variant: "error" });
    }
  };
  const handleLoginAsCustomer = async (row) => {
    console.log(row, "row_project");
    const postData = {
      user_id: row.user._id,
    };
    // const token_resp = await _get_customer_token_for_login(postData);
    // if (token_resp.code == 200) {
    //   const URL = `${clientBaseUri}/controller/login/${token_resp.token}`;
    //   window.open(URL, "_blank");
    // } else {
    //   enqueueSnackbar(token_resp.message, { variant: "error" });
    // }
  };
  const handleEditClick = (row) => {
    setSelectedRow(row);
    handleOpenEdit();
  };

  const handleEditSourceCode = (row) => {
    navigate("/projects/edit-source-code/project/" + row._id, {
      state: {
        project: row,
      },
    });
  };

  const handleVersionsClick = (row) => {
    navigate("/projects/" + row._id + "/versions");
    console.log("clicked");
  };

  const handlePageDetails = (row) => {
    navigate(`/projects/${row._id}`);
    console.log("projectlistpage");
  };


  const handleViewS3file = (row) => {
    navigate(`/viewS3files/${row._id}`);
    console.log("views3files");
  };

  const handleDelete = async (selectedRow) => {
    const response = await _delete_project(selectedRow._id);
    if (response.code === 200) {
      setOpenDelete(false);
      get_project_list();
    }
    const color = response.code === 200 ? "success" : "error";
    enqueueSnackbar(response.message, { variant: color });
  };
  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit Project",
      icon: "akar-icons:edit",
      handleClick: handleEditClick,
      hide: false,
    },
    {
      label: "Edit Source Code",
      icon: "akar-icons:edit",
      handleClick: handleEditSourceCode,
      hide: false,
    },
    {
      label:
        versions > 0 ? `Project Versions (${versions})` : "Project Versions",
      icon: "octicon:versions-16",
      handleClick: handleVersionsClick,
      hide: versions === 0,
    },
    {
      label: "View Project Details",
      icon: "akar-icons:eye",
      handleClick: handlePageDetails,
      hide: false,
    },

    {
      label: "View S3 Files ",
      icon: "akar-icons:eye",
      handleClick: handleViewS3file,
      hide: false,
    },

    {
      label: "Login As Customer",
      icon: "carbon:login",
      handleClick: handleLoginAsCustomer,
      hide: false,
    },
    {
      label: "Delete Project",
      icon: "ep:delete",
      handleClick: handleDeleteClick,
      hide: false,
    },
  ];

  const get_valid_link = (domain, ssl_status) => {
    let _domain = domain;

    _domain = _domain.replaceAll("https://", "");
    _domain = _domain.replaceAll("http://", "");
    if (ssl_status) {
      return "https://" + _domain;
    } else {
      return "http://" + _domain;
    }
  };

  const handleRefresh = () => {
    get_project_list();
  };



  const handleprojectdetailClick =(row)=>{
    navigate(`/projects/${row._id}`);
  }
  useEffect(() => {
    debounce(get_project_list);
  }, [page, rowsPerPage, filterName]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
       
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Projects
          </Typography>
          
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {data
                    
                    .map((row, i) => {
                      const {
                        _id,
                        project_name,
                        user_name,
                        status,
                        preview_link,
                        domain,
                        is_published,
                        ssl_status,
                        updatedAt,
                        createdAt,
                        ssl_configured,
                        version_count,
                        project_pages_count,
                      } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{page * rowsPerPage + i + 1}</TableCell>
                          <TableCell  style={{ whiteSpace: "nowrap" }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                className="text-capitalize"
                                variant="subtitle2"
                                onClick={()=>handleprojectdetailClick(row)}
                                style={{cursor:"pointer"}}
                              >
                                {project_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{_id}</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {user_name}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {domain ? (
                              <a
                                href={get_valid_link(domain, ssl_status)}
                                target="_blank"
                              >
                                {domain}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell width={200} className="pl-0" align="left">
                            <Label variant="ghost" color="success">
                              {project_pages_count}
                            </Label>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            preview_link={preview_link}
                          >
                            <a href={preview_link} target="_blank">
                              <Button>Preview</Button>
                            </a>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={(!is_published && "error") || "success"}
                            >
                              {is_published ? "Yes" : "No"}
                            </Label>
                          </TableCell>{" "}
                          
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                ssl_status
                                  ? ssl_configured
                                    ? "success"
                                    : "info"
                                  : "error"
                              }
                            >
                              {ssl_status && ssl_configured && "Yes"}
                              {ssl_status && !ssl_configured && "Processing"}
                              {!ssl_status && !ssl_configured && "No"}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(createdAt).format("LLL")}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(updatedAt).format("LLL")}
                          </TableCell>
                          <TableCell align="right">
                            <CustomPopover
                              data={row}
                              menu={MENU_OPTIONS}
                              setVersions={setVersions}
                              projects={true}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {isUserNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this project?"}
              handleAgree={(e) => handleDelete(selectedRow)}
            />
          </>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>

      <EditProject
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenEdit}
        onCloseDrawer={handleCloseEdit}
        selectedRow={selectedRow}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
