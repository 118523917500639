import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Label from "../../components/Label";
import {
  _delete_manage_payment,
  _project_subscriber_listing,
} from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import CustomConfirmation from "src/components/CustomConfirmation";
import { useSnackbar } from "notistack";

import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

export default function ManagePaymentList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(true); // Loading state

  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, setOpenDelete] = useState(false);

  const pageSubscriberList = async () => {
    const project_list_response = await _project_subscriber_listing(id);
    setData(project_list_response.project);
    setLoading(false);
    // console.log(project_list_response, "managesublist");

    // console.log(data, "datattatat");
  };

  useEffect(() => {
    pageSubscriberList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    const delete_resp = await _delete_manage_payment(selectedPage._id);
    console.log("Delete page:", delete_resp);

    if (delete_resp.code == 200) {
      setOpenDelete(false);
      pageSubscriberList();
      enqueueSnackbar("Page Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  const getCurrencySign = (currencyType) => {
    switch (currencyType) {
      case "USD ":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";

      default:
        return "";
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell># </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Plan Name </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Amount</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Currency Type</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Interval Time</TableCell>
                <TableCell> Status</TableCell>
                <TableCell> Order</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Payment Type</TableCell>

                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.project_plans && data.project_plans.length > 0 ? (
                data.project_plans.map((dat, index) => (
                  <TableRow
                    key={dat.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell>{dat.plan_name}</TableCell>
                    <TableCell>{dat.amount}</TableCell>
                    <TableCell>
                      {" "}
                      {getCurrencySign(dat.currency_type)} {dat.currency_type}
                    </TableCell>

                    <TableCell>{dat.interval_time}</TableCell>
                    <TableCell>
                    <Label
                        variant="ghost"
                        color={(!dat.status && "error") || "success"}
                       
                      >
                      {dat.status === true ? "True" : "False"}

                      </Label>
                    </TableCell>
                    <TableCell> {dat.order} </TableCell>

                    <TableCell>{dat.payment_type}</TableCell>

                    <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(dat.createdAt).format("LLL")}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(dat.updatedAt).format("LLL")}</TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="page-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuOpen(e, dat)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="page-menu"
                        anchorEl={anchorEl}
                        open={Boolean(
                          anchorEl && selectedPage && selectedPage.id === dat.id
                        )}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Manage Payment list  Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
    </>
  );
}
