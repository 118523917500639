import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { detail_project_for_admin } from "src/DAL/projects/projects";

import {
  Pages,
  People,
  Domain,
  CompareArrows,
  Payment,
  CreditCard,
} from "@mui/icons-material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import PagesDetailList from "./PagesDetailList";
import Card from "@mui/material/Card";
import SubscriberList from "./SubscriberList";
import DomainList from "./DomainList";
import VersionList from "./VersionList";
import ManagePaymentList from "./ManagePaymentList";
import TransactionList from "./TransactionList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  const { id } = useParams();
  const [data, setData] = useState([]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectDetail = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={handleGoBack} aria-label="Go back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Project Details</Typography>
      </Box>

      <Box sx={{ mb: 2, mt: 1 }}>
        <DomainList />
      </Box>

      <Card sx={{ p: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Tab label="Pages" {...a11yProps(0)} />
              <Tab label="Subscribers" {...a11yProps(1)} />

              <Tab label="Versions" {...a11yProps(2)} />
              <Tab label="Manage Payment" {...a11yProps(3)} />
              <Tab label="Transactions" {...a11yProps(4)} />
            
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Typography variant="h5" sx={{ py: 1 }}>
              Pages Details
            </Typography>
            <PagesDetailList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="h5" sx={{ py: 1 }}>
              Subscribers
            </Typography>
            <SubscriberList />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Typography variant="h5" sx={{ py: 1 }}>
              Version
            </Typography>
            <VersionList />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Typography variant="h5" sx={{ py: 1 }}>
              Manage Payment
            </Typography>
            <ManagePaymentList />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography variant="h5" sx={{ py: 1 }}>
              Transactions
            </Typography>
            <TransactionList />
          </TabPanel>
          
        </Box>
      </Card>
    </>
  );
};

export default ProjectDetail;
