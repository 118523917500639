import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  _delete_project_subscriber_by_email,
  _project_subscribers_list,
} from "../../DAL/projects/projects";
import Loader from "../../components/Loader/Loader";
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import account from "../../_mocks_/account";
import { LoadingButton } from "@mui/lab";
import Label from "../../components/Label";
import {
  _add_support_ticket_comment,
  _delete_support_ticket_comment,
  _support_ticket_details,
} from "src/DAL/support_tickets/support_tickets";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { get_local_preview } from "src/utils/utils";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { _get_user } from "src/DAL/localStorage/localStorage";
import {
  _close_support_ticket,
  _delete_support_ticket,
} from "src/DAL/support_tickets/support_tickets";
import {
  _get_customer_token_for_login,
} from "src/DAL/customer/customer";
import { clientBaseUri } from "src/config/config";

function SupportTicketDetails() {
  const params = useParams();
  const navigate = useNavigate();

  const ticket_id = params?.ticket_id;

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [ticketData, setTicketData] = useState("");
  const [comments, setComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [Templates, setTemplates] = useState([[], []])
  const [inputs, setInputs] = useState({
    message: "",
    image: "",
  });

  const [openDelete_Ticket, setopenDelete_Ticket] = useState(false);
  const [openComplete_Ticket, setopenComplete_Ticket] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickRemove = () => {
    setInputs({
      ...inputs,
      image: "",
    });
  };

  const handleDeleteClick = (row) => {
    setSelectedComment(row);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    const delete_resp = await _delete_support_ticket_comment(
      selectedComment._id
    );
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      fetchDetails();
      setOpenDelete(false);
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };
  const handleDeleteClick_Token = () => {
    setopenDelete_Ticket(true);
  };

  const handleCompleteClick_Token = () => {
    setopenComplete_Ticket(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },

  ];

  const handleDelete_Ticket = async () => {
    const delete_resp = await _delete_support_ticket(ticket_id);
    if (delete_resp.code == 200) {
      enqueueSnackbar(delete_resp.message, { variant: "success" });
      navigate("/support-tickets")
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleComplete_Ticket = async () => {
    const delete_resp = await _close_support_ticket(ticket_id);
    if (delete_resp.code == 200) {
      setopenComplete_Ticket(false)
      fetchDetails();
      enqueueSnackbar(delete_resp.message, { variant: "success" });

    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };
  const handleLoginAsCustomer = async () => {
    console.log("customer Id is : ", ticketData.user_id._id)
    const postData = {
      user_id: ticketData.user_id._id,
    };
    const token_resp = await _get_customer_token_for_login(postData);
    if (token_resp.code == 200) {
      const URL = `${clientBaseUri}/admin-auth-controller/${token_resp.token}`;
      window.open(URL, "_blank");
    } else {
      enqueueSnackbar(token_resp.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS_Ticket = (status) => {
    if (status === 0) {
      return [
        {
          label: "Close Ticket",
          icon: "carbon:task-complete",
          handleClick: handleCompleteClick_Token,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleDeleteClick_Token,
        },
        {
          label: "Login As Customer",
          icon: "carbon:login",
          handleClick: handleLoginAsCustomer,
        },
      ];
    }

    return [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDeleteClick,
      },
      {
        label: "Login As Customer",
        icon: "carbon:login",
        handleClick: handleLoginAsCustomer,

      }
    ];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // upload image
    if (inputs.image) {
      setIsLoadingSend(true);
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.image);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const postData = {
          support_ticket_id: ticket_id,
          message: inputs.message,
          image: image_resp.file_name,
        };

        const resp = await _add_support_ticket_comment(postData);
        if (resp.code === 200) {
          setIsLoadingSend(false);
          setInputs({
            message: "",
            image: "",
          });
          fetchDetails();
        } else {
          setIsLoadingSend(false);
          enqueueSnackbar(resp.message, { variant: "error" });
        }
      }
    } else {
      const postData = {
        support_ticket_id: ticket_id,
        message: inputs.message,
        image: inputs.image,
      };

      setIsLoadingSend(true);
      const resp = await _add_support_ticket_comment(postData);
      if (resp.code === 200) {
        setIsLoadingSend(false);
        setInputs({
          message: "",
          image: "",
        });
        fetchDetails();
      } else {
        setIsLoadingSend(false);
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
  };

  const fetchDetails = async () => {
    const resp = await _support_ticket_details(ticket_id);
    if (resp.code === 200) {
      setTicketData(resp.support_ticket);
      let name = ticketData.subject;
      console.log("userData", resp.support_ticket_comment_array)
      setComments(resp.support_ticket_comment_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }

  };

  useEffect(() => {
    fetchDetails();
    if (ticketData) {
      let name = [];
      name.push(ticketData.user_id);
      console.log("User Detail", ticketData)
      setTemplates(
        [
          ["Hi User", "Hi " + ticketData.user_id.first_name + " " + ticketData.user_id.last_name + ", "],
          ["Greetings", "Thanks for reaching out to our support team. "],
          ["Regards", "Regards\nDynamite Digital Support Team "],
          ["Ending Signature", "Regards Dynamite Digital Support Team "],
          ["Thanks & Reagards", "Thanks & Regards\nDynamite Digital Support Team "],
          ["Feedback", "Please Let us know if this helps you "],
          ["Reminder", "Hi, " + ticketData.user_id.first_name + " " + ticketData.user_id.last_name + " we hope your issue has been resolved kindly give your feedback\nRegards Dynamite Digital Support Team "]
        ]
      );
    }
  }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)) {
      return true;
    }
    else {
      return false;
    }
  }


  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <IconButton
              onClick={() => {
                navigate("/support-tickets");
              }}
              aria-label="delete"
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <div className="d-flex justify-content-between">

              <h4 className="text-capitalize"  >
                {ticketData.subject}



              </h4>
              <Label className="m-2" variant="" color="success">
                {moment(ticketData.createdAt).format("LLL")}

              </Label>
            </div>
            <Typography className="m-1" variant="h6" gutterBottom>{ticketData.user_id.first_name + " " + ticketData.user_id.last_name}</Typography>



            <div className="m-2 d-flex justify-content-between">
              {!CheckActivityTime(ticketData.last_action_date) && ticketData.ticket_status === 0 && (
                <Label style={{ width: 70 }} variant="ghost" color="info">
                  OPEN
                </Label>
              )}

              {ticketData.ticket_status === 1 && (
                <Label style={{ width: 70 }} variant="ghost" color="success">
                  CLOSED
                </Label>
              )}
              {
                CheckActivityTime(ticketData.last_action_date) && ticketData.ticket_status === 0 && (
                  <Label
                    // style={{ width: 110 }}
                    variant="ghost"
                    color="error"
                  >
                    Not Responding
                  </Label>
                )}
              <div className="m-0">
                <CustomPopover menu={MENU_OPTIONS_Ticket(ticketData.ticket_status)} />
              </div>
            </div>


          </div>

          <div className="col-12 mb-3">
            {ticketData.image && (
              <img
                width="100%"
                src={s3baseUrl + "/" + ticketData.image}
                alt=""
              />
            )}
          </div>
          <div className="col-12 mb-4">
            <Typography
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              gutterBottom
            >
              {ticketData.description}
            </Typography>
          </div>

          {comments.map((comment) => (
            <div className="col-12 mt-2">
              <div className="support-ticket-wrapper border-top">
                <div className="support-profile me-3 mt-2">
                  <Avatar
                   
                    style={{ width: 50, height: 50 }}
                    src={s3baseUrl + "/" + comment.user?.profile_image}
                    alt={comment.user?.first_name}
                  />
                </div>
                <div className="w-100">
                  <div className="mt-2">
                    <Typography
                      className="mb-0 text-capitalize"
                      variant="subtitle1"
                      gutterBottom
                    >
                      {comment.user?.first_name + " " + comment.user?.last_name}
                    </Typography>
                    {comment.user?.type === "0" && (
                      <div className="float-end">
                        <CustomPopover data={comment} menu={MENU_OPTIONS} />
                      </div>
                    )}
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                      noWrap
                    >
                      {moment(comment.createdAt).fromNow()}
                    </Typography>
                  </div>
                  <div className="mt-1">
                    <Typography
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="p"
                      gutterBottom
                    >
                      {comment.message}
                    </Typography>
                  </div>

                  <div className="mt-1">
                    <a href={s3baseUrl + "/" + comment.image} target="_blank">
                      <img
                        width="60"
                        src={s3baseUrl + "/" + comment.image}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <form onSubmit={handleSubmit}>
            <div className="col-12 mt-4">
              <TextField
                fullWidth
                label="Message"
                placeholder="Please add your details here"
                required
                name="message"
                multiline
                rows={5}
                value={inputs.message}
                onChange={handleChange}
              />
            </div>

            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
              {inputs.image ? (
                <div className="d-flex justify-content-start align-items-center">
                  <img
                    width="60"
                    src={get_local_preview(inputs.image)}
                    alt=""
                  />

                  <Button
                    onClick={handleClickRemove}
                    className="mt-1 ms-3"
                    style={{ color: "Red" }}
                    fullWidth
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="image"
                    onChange={handleChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}



              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isLoadingSend}
              >
                Send
              </LoadingButton>
            </div>
            <hr></hr>
            <Stack direction="row" spacing={1} overflow="auto">
              {Templates.map(template => (
                <  Chip
                  onClick={() => {

                    setInputs({ message: inputs.message + template[1] })

                  }}
                  label={template[0]}
                />

              ))}
            </Stack>
            <hr></hr>

          </form>
        </div>
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleDelete}
      />


      <CustomConfirmation
        open={openComplete_Ticket}
        setOpen={setopenComplete_Ticket}
        title={"Are you sure you want to close this Support Ticket?"}
        handleAgree={handleComplete_Ticket}
      />

      <CustomConfirmation
        open={openDelete_Ticket}
        setOpen={setopenDelete_Ticket}
        title={"Are you sure you want to delete this support ticket?"}
        handleAgree={handleDelete_Ticket}
      />
    </>
  );
}

export default SupportTicketDetails;
