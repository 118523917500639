import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_customer_by_admin,
  _update_customer_password,
} from "src/DAL/customer/customer";
import CountrySelect from "src/components/CountrySelector/CountrySelector";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddCustomer({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [businessType, setBusinessType] = useState("");
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    // confirm_password: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    business_name: "",
    business_type: "",
    // business_country: '',
    post_code: "",
    // address: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    formData.append("business_type", businessType);
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("business_name", inputs.business_name);
    // formData.append("contact_number", inputs.contact_number);

    if (inputs.contact_number.length > 3) {
      formData.append("contact_number", inputs.contact_number);
    }

    formData.append("email", inputs.email);
    formData.append("password", inputs.password);
    formData.append("post_code", inputs.post_code);
    formData.append("type", 1);

    handleUpdatePassword(formData);
  };

  const handleUpdatePassword = async (formData) => {
    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      contact_number: inputs.contact_number,
      email: inputs.email,
      password: inputs.password,
      // confirm_password: inputs.confirm_password,
      business_name: inputs.business_name,
      business_type: inputs.business_type,
      // business_country: inputs.business_country,
      post_code: inputs.post_code,
      type: 1,
    };

    const add_customer_resp = await _add_customer_by_admin(formData);
    if (add_customer_resp.code == 200) {
      onCloseDrawer();
      enqueueSnackbar(`Customer added successfully`, {
        variant: "success",
      });
      handleRefresh();
      setSelectedOptions([]); // Reset the selected business types
      setBusinessType(""); // Reset the business type
    } else {
      enqueueSnackbar(add_customer_resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  // const handleCountryChange = (country) => {
  //   console.log(country, "es");
  //   setInputs({
  //     ...inputs,
  //     business_country: country.label,
  //   });
  // };

  const handleSelectChange = (selectedOptions) => {
    // setBusinessType([...selectedOptions]);
    console.log(businessType, "asjasasas");
    setSelectedOptions(selectedOptions);
    setBusinessType(
      JSON.stringify(selectedOptions.map((option) => option.value))
    );
  };

  const handleChangePhoneNumber = (phone) => {
    setInputs({
      ...inputs,
      contact_number: phone,
    });
  };

  useEffect(() => {
    setInputs({
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      contact_number: "",
      business_name: "",
      business_type: "",
      post_code: "",
    });
    setIsLoading(false);
  }, [isOpenDrawer]);

  const options = [
    { value: "Fitness", label: "Fitness" },
    { value: "Motivational", label: "Motivational" },
    { value: "Travelling", label: "Travelling" },
    { value: "Education", label: "Education" },
    { value: "Business", label: "Business" },
  ];

  const phoneInputStyles = {
    height: "48px", // Adjust the height value as needed
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid #bdbdbd",
    borderRadius: "4px",
    padding: "8px 12px",
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Customer
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}

              <TextField
                fullWidth
                label="First Name"
                required
                name="first_name"
                value={inputs.first_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Last Name"
                required
                name="last_name"
                value={inputs.last_name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Email"
                required
                name="email"
                type="email"
                value={inputs.email}
                onChange={handleChange}
              />

              {/* <TextField
                fullWidth
                label="Address"
                required
                name="address"
                value={inputs.address}
                onChange={handleChange}
              /> */}
              {/* <TextField
                fullWidth
                label="Contact Number"
                required
                type="number"
                name="contact_number"
                value={inputs.contact_number}
                onChange={handleChangePhoneNumber}
              /> */}

              <Typography variant="subtitle2" color="textSecondary">
                Phone Number
                <PhoneInput
                  country={"us"}
                  className="mt-0"
                  value={inputs.contact_number}
                  // onChange={handleChangePhoneNumber}
                  onChange={(value) => {
                    if (value.length > 3) {
                      handleChangePhoneNumber(value);
                    }
                  }}
                  inputStyle={{
                    height: 50,
                    width: "100%",
                  }}
                />
              </Typography>
              <TextField
                fullWidth
                label="Password"
                required
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Post Code"
                required
                name="post_code"
                value={inputs.post_code}
                onChange={handleChange}
              />
              {/* <TextField
                fullWidth
                label="Confirm Password"
                required
                name="confirm_password"
                value={inputs.confirm_password}
                onChange={handleChange}
              /> */}

              <TextField
                fullWidth
                label="Business Name"
                required
                name="business_name"
                value={inputs.business_name}
                onChange={handleChange}
              />

              <Typography variant="subtitle2" color="textSecondary">
                Business Type
                <Select
                  options={options}
                  value={selectedOptions}
                  isMulti
                  name="business_type"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                />
              </Typography>

              {/* <TextField
                fullWidth
                label="Business Type"
                required
                name="business_type"
                value={inputs.business_type}
                onChange={handleChange}
              /> */}

              {/* <CountrySelect onChange={handleCountryChange} /> */}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Customer
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
