import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConfirmation from "src/components/CustomConfirmation";
import { _delete_transaction, _project_subscriber_listing } from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import Label from "../../components/Label";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { localTime } from "src/utils/utils";
import { useSnackbar } from "notistack";

export default function TransactionList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(true); // Loading state


  const [openDelete, setOpenDelete] = useState(false);


  const pageSubscriberList = async () => {
    const project_list_response = await _project_subscriber_listing(id);
    setData(project_list_response.project);
    setLoading(false);
    console.log(project_list_response, "managesublist");

    console.log(
      project_list_response.project.project_transaction,
      "transaction"
    );

    console.log(data, "datattatat");
  };

  useEffect(() => {
    pageSubscriberList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    const delete_resp = await _delete_transaction(selectedPage._id);
    console.log("Transactionpage:", delete_resp);
    console.log("Transactionpage:", selectedPage._id);

    if (delete_resp.code == 200) {
      pageSubscriberList();
      setOpenDelete(false);
      enqueueSnackbar("Transaction Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };

  const handleDeleteClick = () => {
    
    setOpenDelete(true);
  };

  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  const getCurrencySign = (currencyType) => {
    switch (currencyType) {
      case "USD || usd":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";

      default:
        return "";
    }
  };

  return (

    <>
    <TableContainer component={Paper}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>First Name </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Card</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Card Brand</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Payment Type</TableCell>
              <TableCell>Currency</TableCell>
              {/* <TableCell>Invoice URL</TableCell>
              <TableCell>Invoice PDF</TableCell> */}
              <TableCell style={{ whiteSpace: 'nowrap' }}>Transaction Date</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Transaction Status</TableCell>
              {/* <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell> */}

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.project_transaction && data.project_transaction.length > 0 ? (
              data.project_transaction.map((dat, index) => (
                <TableRow
                  key={dat.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index +1}
                  </TableCell>
                  <TableCell>{dat.subscription_object.first_name}</TableCell>
                  <TableCell>{dat.subscription_object.last_name}</TableCell>
                  <TableCell>{dat.subscription_object.email}</TableCell>

                  <TableCell>{dat.amount}</TableCell>
                  <TableCell>{dat.card}</TableCell>
                  <TableCell> {dat.card_brand} </TableCell>

                  <TableCell>{dat.payment_type}</TableCell>

                  <TableCell> {dat.currency}</TableCell>

                  {/* <TableCell>
                    {" "}
                      {dat.hosted_invoice_url}
                  </TableCell>

                  <TableCell>
                    {" "}
                      {dat.invoice_pdf}
                  </TableCell> */}

                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {moment(localTime(dat.transaction_date)).format("LLL")}
                  </TableCell>

                  <TableCell style={{ textAlign: 'center' }}>

                  <Label
                        variant="ghost"
                        color={(!dat.transaction_status && "error") || "success"}
                       
                      >
                    {dat.transaction_status === "payment_intent.succeeded"
                      ? "Succeed"
                      : "Failed"}

                      </Label>
                  </TableCell>

                  {/* <TableCell>{moment(dat.createdAt).format("LLL")}</TableCell>
                  <TableCell>{moment(dat.updatedAt).format("LLL")}</TableCell> */}

                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="page-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuOpen(e, dat)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="page-menu"
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl && selectedPage && selectedPage.id === dat.id
                      )}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No Transaction  Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>


<CustomConfirmation
open={openDelete}
setOpen={setOpenDelete}
title={"Are you sure you want to delete this customer?"}
handleAgree={handleDeletePage}
/></>
  );
}
