import { useSnackbar } from "notistack";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { _admin_dashboard } from "src/DAL/login/login";
const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export function ContextApi({ children }) {
    // states
    const { pathname } = useLocation();
    
  const [dashboard, setDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
    const [test, setTest] = useState("arslna ashiq");
    const getProfile = () => {
        if (localStorage.getItem("token")) {
      return JSON.parse(localStorage.getItem("user"));
    }
    return null;
  };

  const setProfile = (user) => {
      localStorage.setItem("user", JSON.stringify(user));
    };
    
    const [user, setUser] = useState(getProfile());
  // functions and handlers

  const { enqueueSnackbar } = useSnackbar();

  // const get_dashboard_data = async () => {
  //   const resp = await _admin_dashboard();

  //   if (resp.code == 200) {
  //     const _dashboard = resp.Dashboard;

  //     setDashboard(_dashboard);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(resp.message, { variant: "error" });
  //   }
  // };

  // useEffect(() => {
  //   get_dashboard_data();
   
  // }, [pathname]);
  //   export collection
  const collection = {
    // export states
    test,
    setTest,
    getProfile,
    setProfile,
    //export  handlers
    setUser,
    user,
    isLoading,setIsLoading, dashboard,setDashboard
  };
  return (
    <AppContext.Provider value={collection}>{children}</AppContext.Provider>
  );
}
