import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
// import key from '@iconify/icons-eva/key';
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Drawer,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//
import account from "../../_mocks_/account";
import { Password } from "@mui/icons-material";
import EditAdmin from "src/pages/Admin/Edit-Admin/EditAdmin";
import ChangePassword from "src/pages/Admin/ChangePassword/ChangePassword";
import { s3baseUrl } from "src/config/config";
import { useAppContext } from "src/Hooks/AppContext";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Edit Profile",
    // icon: personFill,
    linkTo: "#",
  },

  // {
  //   label: 'Edit Profile',
  //   // icon: personFill,
  //   linkTo: '#'
  // },
  {
    label: "Change Password",
    // icon:key,
    linkTo: "#",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({
  profilePicture,
  profileImageURL,
  currentProfileImageURL,

  
}) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const {user,setProfile} = useAppContext();

  // const [profile,setProfile]= useState(localStorage.getItem("image"));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isChangePasswordDrawerOpen, setIsChangePasswordDrawerOpen] =
    useState(false);
  const [isMenuDisabled, setIsMenuDisabled] = useState(false);
  const navigate = useNavigate();

  const handleOpenChangePasswordDrawer = () => {
    setIsChangePasswordDrawerOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
    setIsMenuDisabled(false); // Disable the menu items when the drawer opens
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setIsMenuDisabled(false); // Enable the menu items when the drawer closes
  };

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
  };


  

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
          {/* <Avatar src={`${s3baseUrl}/${profile}`} alt="photoURL" /> */}
          <Avatar src={`${s3baseUrl}/${user.profile_image}`} alt="photoURL" />
          {/* {console.log(s3baseUrl+"/"+user.profile_image,"acjhscjkaschkjashcjas")} */}


      
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ my: 1 }} /> */}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            // onClick={handleClose}
            onClick={
              option.label === "Edit Profile"
                ? handleOpenDrawer
                : handleOpenChangePasswordDrawer
            }
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => handleLogout()}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>

      <EditAdmin
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        handleClose={handleClose}
        setProfile={setProfile}
      />
      <ChangePassword
        handleClose={handleClose}
        isChangePasswordDrawerOpen={isChangePasswordDrawerOpen}
        setIsChangePasswordDrawerOpen={setIsChangePasswordDrawerOpen}
      />
    </>
  );
}
