import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConfirmation from "src/components/CustomConfirmation";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { amber, green, grey, red } from "@mui/material/colors";

import debounce from "../../../utils/debounce";

import { saveAs } from "file-saver";

import { _project_subscriber_listing } from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";

import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { localTime } from "src/utils/utils";
import { useSnackbar } from "notistack";
import {
  _select_members,
  _transaction_list,
} from "src/DAL/Transactions/Transaction";
import Label from "../../../components/Label";
import { DatePicker, LocalizationProvider } from "@mui/lab";

export default function TransactionList() {
  const { id } = useParams();
  const [dataList, setDataList] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(false); // Loading state
  const [searchTerm, setSearchTerm] = useState("");

  const [members, setMembers] = useState([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [openDelete, setOpenDelete] = useState(false);

  // states for the filter

  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");

  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(15); // Number of rows per page
  // console.log(rowsPerPage, "nhghjgfhfjh");
  const [isFiltering, setIsFiltering] = useState(false);
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    status: "",
    search: "",
  });
  useEffect(() => {
    console.log(filters);
  }, [filters]);
  const handleChangeFilters = (e) => {
    console.log(e.target.value, " dsfdafaf", e.target.name);
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const memberList = async () => {
    const memberlist_response = await _select_members();
    if (memberlist_response.code == 200) {
      setMembers(memberlist_response.data);
    }
    console.log(memberlist_response, "memberlistresponse");
  };

  useEffect(() => {
    memberList();
  }, []);

  const transaction_List = async () => {
    setLoading(true);
    const postData = isFiltering
      ? {
          ...filters,
          start_date: filters.start_date
            ? moment(filters.start_date).format("MM-DD-YYYY")
            : "01-01-2022", // Default start date if no date is selected
          end_date: filters.end_date
            ? moment(filters.end_date).format("MM-DD-YYYY")
            : "",
        }
      : {
          start_date: "01-01-2022", // Default start date if no date is selected
          end_date: "",
          status: "",
          search: "",
        };

    if (!filters.start_date) {
      postData.start_date = "01-01-2022"; // Update start date if no date is selected
    }

    // Add pagination parameters

    const transaction_list_response = await _transaction_list(
      postData,
      page,
      rowsPerPage
    );
console.log(transaction_list_response,"asckjsacaslckjasklcjs")
    if (transaction_list_response.code == 200) {
      setDataList(transaction_list_response.data);
    } else {
      enqueueSnackbar(transaction_list_response.message,{variant:"error"})
    }
    setLoading(false);

    // console.log(transaction_list_response, "transaction");

    // console.log(
    //   transaction_list_response.data.transaction,
    //   "transactionlistingggggg"
    // );

    // console.log(dataList, "datattatat");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

 

  const downloadFile = (url, e) => {
    e.preventDefault();
    try {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("onclick", (evt) => evt.preventDefault());

      link.download = url.split("/").pop();
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleFilterClick = () => {
    // setIsFiltering(true);

    if (filters.start_date && !filters.end_date) {
      enqueueSnackbar("Enter End Date ", { variant: "error" });
    } else {
      setIsFiltering(true);
    }
  };

  const handleClearFilterClick = () => {
    setIsFiltering(false);
    setFilters({
      start_date: "",
      end_date: "",
      status: "",
      search: "",
    });
  };

  // useEffect(() => {
  //   setPage(0);
  // }, [rowsPerPage, isFiltering]);

  useEffect(() => {
    transaction_List();
  }, [isFiltering, page, rowsPerPage]);

  // useEffect(() => {
  //   debounce(_transaction_List);
  // }, [searchTerm]);

  const handleResetFilters = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setSearchTerm("");
    setIsFiltering(false);

    transaction_List();
    setFilters({
      start_date: "",
      end_date: "",
      status: "",
      search: "",
    });
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Transactions
        </Typography>
        <Card sx={{ p: 2 }}>
          {/* <Box sx={{ mb: 2 }}>
          <TextField
            label="Search by Name"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setDebouncedSearchTerm(e.target.value); 
            }}
          />
        </Box> */}

          <form onSubmit={handleSubmit}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              margin={2}
            >
              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-selec-label">
                  Select Member
                </InputLabel>
                <Select
                  labelId="demo-simple-selec-label"
                  id="demo-simple-select"
                  name="search"
                  value={filters.search}
                  label="Select Member"
                  onChange={handleChangeFilters}
                >
                  <MenuItem value="">Reset</MenuItem>
                  {members.map((item, index) => (
                    <MenuItem key={item.id} value={item}>
                      {item}
                      
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ mb: 0, width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={filters.status}
                  label="Status"
                  onChange={handleChangeFilters}
                >
                  <MenuItem value="">Reset</MenuItem>
                  <MenuItem value="payment_intent.payment_failed">
                    Rejected
                  </MenuItem>
                  <MenuItem value="payment_intent.succeeded">Succeed</MenuItem>
                  <MenuItem value="payment_intent.requires_action">
                    Pending
                  </MenuItem>
                  <MenuItem value="payment_intent.canceled">Cancel</MenuItem>
                  <MenuItem value="customer.subscription.deleted">
                    Delete
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="start-date"
                label="Start Date"
                name="start_date"
                type="date"
                defaultValue=""
                sx={{ mb: 2, width: "100%" }}
                value={moment(filters.start_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    start_date: moment(e.target.value).format("MM/DD/YYYY"),
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="d-flex align-items-center w-100">
                <TextField
                  id="end-date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  defaultValue=""
                  sx={{ width: "100%" }}
                  value={moment(filters.end_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      end_date: moment(e.target.value).format("MM/DD/YYYY"),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <IconButton aria-label="delete" sx={{ border: 0 }}>
                  <RotateLeftIcon onClick={handleResetFilters} />
                </IconButton> */}
              </div>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              margin={2}
              // marginRight={7}
            >
              <Button
                variant="contained"
                onClick={handleFilterClick}
                disabled={isFiltering}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                onClick={handleClearFilterClick}
                disabled={!isFiltering}
              >
                Clear Filter
              </Button>
            </Stack>
          </form>

          <TableContainer component={Paper}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      #
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Card
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      Card Brand
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Date
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Status
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Updated At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Invoice URL
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Invoice PDF
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.transaction && dataList.transaction.length > 0 ? (
                    dataList.transaction
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((dat, index) => (
                        <TableRow
                          key={dat.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.customer_name}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.amount}
                          </TableCell>
                          <TableCell>{dat.currency.toUpperCase()}</TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {dat.card}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {" "}
                            {dat.card_brand}{" "}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.transaction_date).format("LLL")}
                          </TableCell>

                          <TableCell className="pl-0" align="center">
                            <Label
                              variant="ghost"
                              color={
                                (dat.transaction_status ===
                                  "payment_intent.succeeded" &&
                                  "success") ||
                                (dat.transaction_status ===
                                  "payment_intent.payment_failed" &&
                                  "error") ||
                                (dat.transaction_status ===
                                  "payment_intent.requires_action" &&
                                  "warning") ||
                                (dat.transaction_status ===
                                  "payment_intent.canceled" &&
                                  "warning") ||
                                (dat.transaction_status ===
                                "customer.subscription.deleted"
                                  ? "warning"
                                  : "default")
                              }
                            >
                              {dat.transaction_status ===
                              "payment_intent.succeeded"
                                ? "Succeed"
                                : dat.transaction_status ===
                                  "payment_intent.payment_failed"
                                ? "Rejected"
                                : dat.transaction_status ===
                                  "payment_intent.requires_action"
                                ? "Pending"
                                : dat.transaction_status ===
                                  "payment_intent.canceled"
                                ? "Canceled"
                                : dat.transaction_status ===
                                  "customer.subscription.deleted"
                                ? "Deleted"
                                : dat.transaction_status}
                            </Label>
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.createdAt).format("LLL")}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(dat.updatedAt).format("LLL")}
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() =>
                                window.open(dat.hosted_invoice_url, "_blank")
                              }
                            >
                              preview
                            </Button>
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() => window.open(dat.invoice_pdf)}
                            >
                              Download
                            </Button>
                          </TableCell>

                          <TableCell>
                            <IconButton
                              aria-label="more"
                              aria-controls="page-menu"
                              aria-haspopup="true"
                              onClick={(e) => handleMenuOpen(e, dat)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="page-menu"
                              anchorEl={anchorEl}
                              open={Boolean(
                                anchorEl &&
                                  selectedPage &&
                                  selectedPage.id === dat.id
                              )}
                              onClose={handleMenuClose}
                            >
                              <MenuItem>Delete</MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    // : debouncedSearchTerm !== "" ? (
                    //   <TableRow>
                    //     <TableCell colSpan={12} align="center">
                    //       No Search Data Found
                    //     </TableCell>
                    //   </TableRow>
                    // )
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No Transaction Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataList.transaction ? dataList.total_transaction : 0}
            rowsPerPage={rowsPerPage && rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Card>
      </Box>
    </>
  );
}
