import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomConfirmation from "src/components/CustomConfirmation";
import { useSnackbar } from "notistack";
import {
  _backup_project,
  _delete_backup_page,
  _delete_page,
  _delete_zip_of_project,
  _download_zip_backup_file,
  _project_details_listing,
} from "src/DAL/ProjectDetails/projectdetails";
import { useNavigate, useParams } from "react-router-dom";
import dummyImage from "../../images/website-placeholder.png";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

import { _project_backup_restore } from "src/DAL/projects/projects";

const Project = ({ setData, data, loading, setLoading }) => {
  const { id } = useParams();
 
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu

 

  const preview_link = (project) => {
    return s3baseUrl + "/backup/" + project._id + "/index.html";
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [targetDeleteItem, setTargetDeleteItem] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDownloadZip = async (dat) => {
    const postData = {
      path: dat._id,
    };

    console.log(dat, "idiidididid");
    const download_file = await _download_zip_backup_file(postData);
    console.log(download_file, "downloadfilefile");

    if (download_file.code === 200) {
      await downloadZip(`${dat._id}`);

      setTimeout(() => {
        _delete_zip_of_project(postData);
      }, 15000);
    } else {
      enqueueSnackbar(download_file.message, { variant: "error" });
    }
  };

  const downloadZip = async (name) => {
    const zip_path = `${s3baseUrl}/backup/${name}/backup/${name}.zip`;
    console.log(zip_path, "meho ba[ajsn");
    let download_button = document.createElement("a");
    download_button.href = zip_path;
    download_button.click();
    return true;
  };

  const handleDeletePage = async () => {
    const delete_resp = await _delete_backup_page(targetDeleteItem._id);
    console.log("Delete backuppage:", delete_resp);

    if (delete_resp.code == 200) {
      setOpenDelete(false);
      // pageBackup();
      setData((prev) => {
        return prev.filter((row) => row._id !== targetDeleteItem._id);
      });
      setTargetDeleteItem();
      enqueueSnackbar("Project Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };
  console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  const handleDeleteClick = (row) => {
    setTargetDeleteItem(row);

    setOpenDelete(true);
  };

  const handleRestoreClick = async (data) => {
    let post_data = {
      user_id: data.user_id,
    };
    console.log(post_data, "datdat");
    console.log(data, "datadata");

    const response = await _project_backup_restore(post_data, data._id);
    console.log(response, "responseeee");
    if (response.code == 200) {
      // Show success message using Notistack
      enqueueSnackbar("Project Restore successful!", { variant: "success" });
    } else {
      // Show error message using Notistack
      enqueueSnackbar("An error occurred during project restore.", {
        variant: "error",
      });
    }
  };

  const handleEditSourceCode = (row) => {
    navigate("/projects/edit-source-code/backup/" + row._id, {
      state: {
        project: row,
      },
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>Type</TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Update At
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Created At
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Preview</TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  View Source Code
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Download</TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>Date</TableCell>

                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody>
                {data.length > 0 ? (
                  data.map((dat, index) => (
                    <TableRow
                      key={dat.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {dat.type}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {moment(dat.updatedAt).format("LLL")}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {moment(dat.createdAt).format("LLL")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <a href={preview_link(dat)} target="_blank">
                          <Button variant="outlined">Preview</Button>
                        </a>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Button
                          variant="outlined"
                          onClick={() => handleEditSourceCode(dat)}
                        >
                          View Code
                        </Button>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <a target="_blank">
                          <Button
                            variant="outlined"
                            onClick={() => handleDownloadZip(dat)}
                          >
                            Download
                          </Button>
                        </a>
                      </TableCell>

                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {moment(dat.date).format("LLL")}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          aria-controls="page-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleMenuOpen(e, dat)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="page-menu"
                          anchorEl={anchorEl}
                          open={Boolean(
                            anchorEl &&
                              selectedPage &&
                              selectedPage.id === dat.id
                          )}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => handleDeleteClick(dat)}>
                            Delete
                          </MenuItem>

                          <MenuItem onClick={() => handleRestoreClick(dat)}>
                            Restore
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <Typography>No Page data found.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
    </>
  );
};

export default Project;
