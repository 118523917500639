import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Label from "../../../components/Label";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../components/_dashboard/user";
//
import USERLIST from "../../../_mocks_/user";
import {
  _customer_list,
  _delete_customer,
  _get_customer_token_for_login,
} from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import { ProductFilterSidebar } from "src/components/_dashboard/products";
import UpdateCustomerPassword from "../Update-Customer-Password/UpdateCustomerPassword";
import AddCustomer from "../Add-Customer/AddCustomer";
import { clientBaseUri } from "src/config/config";
import moment from "moment";
import ProjectList from "../Project-List/ProjectList";
import UpdateCustomerProfileName from "../Update-Customer-Profile-Name/UpdateCustomerProfileName";

import debounce from "../../../utils/debounce";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "first_name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "type", label: "User Type", alignRight: false },
  { id: "contact_number", label: "Contact Number", alignRight: false },
  // { id: "business_country", label: "Country", alignRight: false },
  { id: "business_type", label: "Business Type", alignRight: false },
  { id: "business_name", label: "Business Name", alignRight: false },
  // { id: "post_code", label: "Postal Code", alignRight: false },
  { id: "createdAt", label: "Registration Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        // _user.business_country.toLowerCase().indexOf(query.toLowerCase()) !==
        //   -1 ||
        _user.post_code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");

  const [debouncedFilterName, setDebouncedFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [isDrawerOpenProfile, setIsDrawerOpenProfile] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [OpenProjectList, setOpenProjectList] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState();

  // searchdebounce
  // const debouncedFilterName = debounce((value) => {
  //   setFilterName(value);
  // }, 300);

  // const handleFilterByName = (event) => {
  //   const value = event.target.value;
  //   debouncedFilterName(value);
  // };

  const handleFilterByName = (event) => {
    const value = event.target.value;
    setFilterName(value);
  };

  const handleOpenChangePassword = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseChangePassword = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenChangeProfile = () => {
    setIsDrawerOpenProfile(true);
  };

  const handleCloseChangeProfile = () => {
    setIsDrawerOpenProfile(false);
  };

  const handleOpenAdd = () => {
    setIsDrawerOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setIsDrawerOpenAdd(false);
  };

  const handleDelete = async () => {
    console.log(selectedRow, "selectedRow");
    const delete_resp = await _delete_customer(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      // get_customer_list(page, rowsPerPage); ye tab use kry gy jab page reload required hoga

      setData((prev) => {
        return prev.filter((item) => item._id != selectedRow._id);
      });

      enqueueSnackbar("Customer Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const isUserNotFound = data.length === 0;

  const get_customer_list = async () => {
    const postData = {
      search: filterName,
    };
    const customer_list_resp = await _customer_list(
      page,
      rowsPerPage,
      postData
    );

    // console.log(customer_list_resp, "customerlistresponse");
    if (customer_list_resp.code == 200) {
      setTotalPages(customer_list_resp.pages);
      const customers = customer_list_resp.customer.map((customer, index) => {
        return {
          ...customer,
          email: customer.user_id.email,   
          // email is commented bcz after handling error in /customer/project details/pages details  
          count: index + 1 + rowsPerPage * page,
        };
      });
      setData(customers);

      // console.log(customers,"customers ")
      setTotalCount(customer_list_resp.count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(customer_list_resp.message, { variant: "error" });
    }
  };

  const handleUpdatePasswordClick = (row) => {
    setSelectedRow(row.row);
    handleOpenChangePassword();
  };

  const handleUpdateProfileClick = (data) => {
    setSelectedIndex(data.index);
    setSelectedRow(data.row);
    handleOpenChangeProfile();
  };

  const handleShowProjectList = (row) => {
    setSelectedRow(row.row);
    setOpenProjectList(true);
  };

  const handleShowBackups = (row) => {
    setSelectedRow(row.row);
    navigate(`/backup-publish-projects/${row.user_id._id}`);
  };
  const handleDeleteClick = (row) => {
    setSelectedRow(row.row);
    setOpenDelete(true);
  };

  const handleLoginAsCustomer = async (row) => {
    const postData = {
      user_id: row.row.user_id._id,
    };

    console.log(row,"row_customer")
    const token_resp = await _get_customer_token_for_login(postData);
    if (token_resp.code == 200) {
      const URL = `${clientBaseUri}/controller/login/${token_resp.token}`;
      window.open(URL, "_blank");
    } else {
      enqueueSnackbar(token_resp.message, { variant: "error" });
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Update Password",
      icon: "akar-icons:edit",
      handleClick: handleUpdatePasswordClick,
    },
    {
      label: "Edit Customer",
      icon: "akar-icons:edit",
      handleClick: handleUpdateProfileClick,
    },

    {
      label: "Project List",
      icon: "bx:list-ol",
      handleClick: handleShowProjectList,
    },

    {
      label: "Backups",
      icon: "fluent:cloud-backup-48-regular",
      handleClick: handleShowBackups,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },

    {
      label: "Login As Customer",
      icon: "carbon:login",
      handleClick: handleLoginAsCustomer,
    },
  ];

  const handleRefresh = () => {
    get_customer_list(page, rowsPerPage);
  };

  useEffect(() => {
    debounce(get_customer_list);
  }, [page, rowsPerPage, filterName]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, filterName]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {selectedRow && (
        <ProjectList
          open={OpenProjectList}
          setOpen={setOpenProjectList}
          data={selectedRow}
          index={selectedIndex}
        />
      )}

      <div className="container">
        {/* <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Customers
          </Typography>
          <Button
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Customer
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {data
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        _id,
                        first_name,
                        last_name,
                        contact_number,
                        post_code,
                        status,
                        // business_country,
                        email,
                        type,
                        count,
                        createdAt,
                        business_type,
                        business_name,
                      } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{count}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography
                                className="text-capitalize"
                                variant="subtitle2"
                                noWrap
                              >
                                {first_name + " " + last_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {email}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={type === 2 ? "info" : "success"}
                            >
                              {type === 2 ? "Magnetizer" : "Wezily"}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            {contact_number}
                          </TableCell>
                          {/* <TableCell className="pl-0" align="left">
                            {business_country}
                          </TableCell> */}
                          <TableCell className="pl-0" align="left">
                            {business_type.map((item) => (
                              <Typography key={item.value}>{item}</Typography>
                            ))}
                          </TableCell>
                          <TableCell
                            className="pl-0"
                            align="left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {business_name}
                          </TableCell>
                          {/* <TableCell className="pl-0" align="left">
                            {post_code}
                          </TableCell> */}
                          <TableCell
                            className="pl-0"
                            align="left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {moment(createdAt).format("LLL")}
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={MENU_OPTIONS}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isUserNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this customer?"}
              handleAgree={handleDelete}
            />
          </>

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
      <UpdateCustomerPassword
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenChangePassword}
        onCloseDrawer={handleCloseChangePassword}
        selectedRow={selectedRow}
      />

      <AddCustomer
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      />

      <UpdateCustomerProfileName
        isOpenDrawer={isDrawerOpenProfile}
        onOpenDrawer={handleOpenChangeProfile}
        onCloseDrawer={handleCloseChangeProfile}
        selectedRow={selectedRow}
        selectedIndex={selectedIndex}
        setSelectedRow={setSelectedRow}
        get_customer_list={get_customer_list}
        setData={setData}
      />
    </>
  );
}
