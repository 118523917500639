import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  CustomerListing,
  CustomerProjects,
  UpdateCustomerPassword,
} from "./pages/Customers";
import {
  ProjectListing,
  EditProject,
  ProjectVersionsListing,
} from "./pages/Projects";
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import NotFound from "./pages/Page404";
import EditSourceCode from "./pages/Edit-Source-Code/EditSourceCode";
import { HelpingCategories } from "./pages/Helping-Category";
import { HelpingArticles } from "./pages/Helping-Article";
import { SupportDepartments } from "./pages/Support-Departments";
import { SupportTickets } from "./pages/Support-Tickets";
import SupportTicketDetails from "./pages/Support-Ticket-Details/SupportTicketDetails";
import { Listing } from "./pages/Plans";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import TransactionListing from "./pages/Transaction/Listing.jsx/TransactionListing";
import BackupPublicProject from "./pages/BackupPublishProject/BackupPublicProject";
import BackupViewSourceCode from "./pages/Customers/Backup-source/BackupViewSourceCode";
import ViewS3files from "./pages/ProjectDetail/ViewS3files";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};
export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/customers" element={<CustomerListing />} />
        <Route path="/customers/projects/:id" element={<CustomerProjects />} />

        <Route path="/projects" element={<ProjectListing />} />
        <Route path="/projects/edit-project/:id" element={<EditProject />} />

        <Route path="/projects/:id" element={<ProjectDetail />} />
        <Route path="/viewS3files/:id" element={<ViewS3files/>} />
        <Route
          path="/backup-publish-projects/:id"
          element={<BackupPublicProject />}
        />
        <Route
          path="/projects/edit-source-code/:project_type/:project_id"
          element={<EditSourceCode />}
        />

        <Route
          path="/projects/edit-source-code/backup/:project_id"
          element={<BackupViewSourceCode />}
        />
        <Route
          path="/projects/:project_id/versions"
          element={<ProjectVersionsListing />}
        />
        <Route path="/helping-categories" element={<HelpingCategories />} />
        <Route path="/helping-articles" element={<HelpingArticles />} />
        <Route path="/support-departments" element={<SupportDepartments />} />
        <Route path="/support-tickets" element={<SupportTickets />} />
        <Route path="/plans" element={<Listing />} />
        <Route path="/transaction" element={<TransactionListing />} />
        <Route
          path="/support-tickets/:ticket_id"
          element={<SupportTicketDetails />}
        />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />

        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
