import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = ({ waitingResponseCount }) => {
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: getIcon(pieChart2Fill),
    },
    {
      title: "Customers",
      path: "/customers",
      icon: getIcon("clarity:users-line"),
    },
    {
      title: "Projects",
      path: "/projects",
      icon: getIcon("el:website"),
    },
    {
      title: "Help Categories",
      path: "/helping-categories",
      icon: getIcon("carbon:collapse-categories"),
    },
    {
      title: "Help Articles",
      path: "/helping-articles",
      icon: getIcon("ooui:articles-rtl"),
    },
    {
      title: "Support Departments",
      path: "/support-departments",
      icon: getIcon("carbon:category"),
    },
    {
      title: "Plans",
      path: "/plans",
      icon: getIcon("ic:outline-contact-page"),
    },

    {
      title: "Transactions",
      path: "/transaction",
      icon: getIcon("icon-park-outline:transaction"),
    },
    {
      title: `Support Tickets  (${
        waitingResponseCount && waitingResponseCount
      })`,
      path: "/support-tickets",
      icon: getIcon("bx:support"),
    }
  ];
};

export default sidebarConfig;
